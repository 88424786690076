<template>
  <Page
    :mounted.sync="mounted"
  >
    <template v-slot:title>
      <v-icon class="mr-2">
        mdi-image-outline
      </v-icon>
      <span v-if="photo?.id">
        {{ photo.id }}
      </span>
    </template>
    
    <template v-slot:content>
      <v-card
        color="transparent"
        flat
      >
        <v-overlay
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-card>
                <v-img
                  :src="photo.src.list"
                  :lazy-src="photo.src.thumbnail"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-card-text>
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td>Création</td>
                        <td>
                          <span>
                            {{ photo.created_datetime | dateSimple }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Détourage</td>
                        <td>
                          <AutomationChip
                            v-if="photo.clipping"
                            type="clipping"
                            :status="photo.clipping"
                          />
                          <span v-if="photo.processed_datetime">
                            {{ photo.processed_datetime | dateSimple }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Formats</td>
                        <td>
                          <v-list
                            flat
                            color="transparent"
                          >
                            <v-list-item
                              v-for="(url, format) in photo.src"
                              :key="format"
                            >
                              <v-btn
                                :href="url"
                                target="_blank"
                                text
                                outlined
                                small
                                color="blue lighten-1"
                              >
                                {{ format }}
                                <v-icon
                                  right
                                  small
                                >
                                  mdi-open-in-new
                                </v-icon>
                              </v-btn>
                            </v-list-item>
                          </v-list>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <Snackbar />
    </template>
  </Page>
</template>

<script>
import AutomationChip from '@/components/base/Photos/AutomationChip.vue'
import Page from '@/components/core/Page.vue'
import Snackbar from '@/components/core/Snackbar.vue'

export default {
  name: 'PhotosEdit',
  components: { AutomationChip, Snackbar, Page },
  data() {
    return {
      mounted: false,
      loading: false,
      photo: null,
    }
  },
  async mounted() {
    await this.load(this.$route.params.id)
  },
  methods: {
    async load(id) {
      this.loadingText = this.$i18n.t('views.products.forms.messages.load.progress')
      this.loading = true
      
      try {
        const response = await this.$axios.get('v3/photos/' + id)
        this.photo = response.data
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
          error?.response?.data['detail'] ??
          'No context'
        this.snackbarError(this.$i18n.t('views.curation.forms.messages.load.failed', { error: error_message }))
      } finally {
        this.loading = false
        this.mounted = true
      }
    },
  },
}
</script>
